<template>
  <form-field-text
    :ref="`amountField`"
    :clearable="fAmount > 0 && !disabled"
    :disabled="disabled"
    :hint="sAmount"
    :persistent-hint="!!sAmount"
    :value="fAmount"
    :hide-label="hideLabel"
    input-type="number"
    label="amount"
    @input:debounce="setAmount"
  >
    <template
      v-if="amountConvertable && sCurrencyCode && sCurrencyConvertCode"
      #append
    >
      <v-menu offset-y open-on-hover>
        <template #activator="{ on }">
          <v-btn small v-on="on">{{ sCurrencyCode }}</v-btn>
        </template>
        <v-list>
          <v-list-item @click="setCurrencyCode(sCurrencyConvertCode)">
            <v-list-item-title v-text="sCurrencyConvertCode" />
          </v-list-item>
          <v-list-item @click="setCurrencyCode(sCurrencyCode)">
            <v-list-item-title v-text="sCurrencyCode" />
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Component, Prop, PropSync, VModel, Vue } from "vue-property-decorator";
import { number } from "mathjs";
import { isNaN, isNil, isUndefined } from "lodash";
import { currencyConvert, currencyFormat } from "@/plugins/helpers";

@Component
export default class CurrencyValueField extends Vue {
  @VModel({ type: [String, Number], default: "" }) amount!: string | number;
  @PropSync("exchange", { type: [Number, String], default: 0 })
  readonly amountExchange!: string | number;

  @Prop([Number, String]) readonly rate!: number;
  @Prop(String) readonly currency!: string;
  @Prop(String) readonly currencyConvert!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;

  get sCurrencyCode() {
    return this.currency;
  }

  get sCurrencyConvertCode() {
    return this.currencyConvert;
  }

  get fAmount() {
    if (
      !this.currency ||
      !this.currencyConvert ||
      this.currency === this.currencyConvert
    ) {
      return number(this.amount);
    }

    return this.amountExchange;
  }

  get sAmount() {
    if (
      !this.sCurrencyCode ||
      !this.sCurrencyConvertCode ||
      !this.amountConvertable
    ) {
      return undefined;
    }

    const sCode =
      this.sCurrencyCode === this.sCurrencyConvertCode
        ? this.sCurrencyCode
        : this.sCurrencyConvertCode;
    const fValue =
      this.sCurrencyCode === this.sCurrencyConvertCode && this.amountExchange
        ? this.amountExchange
        : this.amount;

    return fValue ? currencyFormat(number(fValue), sCode) : undefined;
  }

  get amountConvertable() {
    return (
      !!this.sCurrencyCode &&
      !!this.sCurrencyConvertCode &&
      this.sCurrencyCode !== this.sCurrencyConvertCode
    );
  }

  setAmount(fValue: number) {
    if (isNil(fValue) || isNaN(fValue)) {
      fValue = 0;
    }

    if (this.amountConvertable) {
      const bInvert = this.sCurrencyCode == "UYU";
      this.convert(fValue, bInvert).then((fVal) => {
        this.$emit("update:exchange", fVal);
      });
    } else {
      this.$emit("input", fValue);
    }
  }

  setCurrencyCode(sCode: string) {
    this.$emit("currency:change", sCode);
  }

  async convert(fAmount: number, bInvert?: boolean): Promise<number> {
    if (!this.amountConvertable || !this.rate || this.rate === 1) {
      return fAmount;
    }

    const sCode =
      this.sCurrencyConvertCode === "UYU"
        ? this.sCurrencyCode
        : this.sCurrencyConvertCode;

    if (isUndefined(bInvert)) {
      bInvert = this.sCurrencyConvertCode === "UYU";
    }

    const obConvert = await currencyConvert(
      fAmount,
      sCode as string,
      this.rate,
      bInvert
    );

    return obConvert ? obConvert.value : fAmount;
  }
}
</script>

<style scoped></style>
